import { 
  welcomeMessage,
  localStorageMessagesKey
} from "shared/constants";

export const getObjectFromStorage = (key) => (
  JSON.parse(sessionStorage.getItem(key))
);

export const setObjectInStorage = (key, object) => (
  sessionStorage.setItem(key, JSON.stringify(object))
);

export const getTimestamp = () => (
  Date.now()
);

export const getIntialMessages = () => {
  let storedMessages = getObjectFromStorage(localStorageMessagesKey);
  return (storedMessages && storedMessages.length) ? storedMessages : [{ text: welcomeMessage, ts: getTimestamp()}];
};

export const isNotWhitespace = (value) => (
  value && /\S/.test(value)
)