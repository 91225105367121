import React from 'react';
import "./style.scss"
import PropTypes from "prop-types";
import { IconBubble } from "shared/components";

const Bubble = ({ onChatShow }) => (
  <div className="Bubble button" onClick={onChatShow}>
    <IconBubble
      width="38.164" 
      height="34.4" 
      viewBox="0 0 38.164 34.4" 
    />
  </div>
);

Bubble.propTypes = {
  onChatShow: PropTypes.func.isRequired,
}

export default Bubble;
