import React from 'react';
import "./style.scss";
import { headerText } from "shared/constants";
import PropTypes from "prop-types";
import { IconClose } from "shared/components";

const Header = ({ onChatHide }) => (
    <div className="Header">
      {headerText}
      <span className="closeButton button" onClick={onChatHide}>
        <IconClose
          viewBox="0 0 29.21 29.21"
          color="#ffffff"
          width="12"
          height="12"
        />
      </span>
    </div>
);

Header.propTypes = {
  onChatHide: PropTypes.func.isRequired,
}

export default Header;