import React from 'react';
import Bubble from './Bubble';
import ChatWindow from './ChatWindow';
import "./App.scss";
import { 
  backendEndpoint,
  localStorageMessagesKey,
} from "shared/constants";
import {
  getIntialMessages,
  getTimestamp,
  isNotWhitespace,
  setObjectInStorage,
} from "shared/helpers";
import axios from "axios";

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      isChatOpen: false,
      userInput: "",
      messages: getIntialMessages(),
      isLoading: false,
    }
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  onUnload = () => {
    const { messages } = this.state;
    setObjectInStorage(localStorageMessagesKey, messages);
  }


  callEndpoint = (userInput) => {
    axios.get(backendEndpoint)
      .then(json => {
        const { messages } = this.state;
        this.setState({
          messages: [...messages, { text: userInput.split("").reverse().join("") }],
          isLoading: false,
        });
      })
      .catch(error => {
        const { messages } = this.state;
        this.setState({
          messages: [...messages, { text: userInput.split("").reverse().join("") }],
          isLoading: false,
        });
      });
  }

  handleMessageSubmit = (e) => {
    const { userInput, messages } = this.state;
    e.preventDefault();
    if (isNotWhitespace(userInput)) {
      this.setState({ 
        messages: [...messages, {
          text: userInput,
          isUser: true,
          ts: getTimestamp(),
        }],
        userInput: "",
        isLoading: true,
       });

      this.callEndpoint(userInput);
    }
  }

  handleInputChange = (e) => {
    this.setState({ userInput: e.target.value });
  }

  handleChatDisplay = () => {
    const { isChatOpen } = this.state;
    this.setState({ isChatOpen: !isChatOpen });
  }

  render() {
    const { isChatOpen, userInput, messages, isLoading } = this.state;

    return (
      <div className="Chat-app">
        {isChatOpen ? (
          <ChatWindow 
            onChatHide={this.handleChatDisplay}
            onMessageSubmit={this.handleMessageSubmit}
            onInputChange={this.handleInputChange}
            inputValue={userInput}
            messages={messages}
            isLoading={isLoading}
          />
        ) : (
          <Bubble 
            onChatShow={this.handleChatDisplay}
          />
        )}
      </div>
    );
  }
}

export default App;
