export const headerText = "kWikibot";

export const welcomeMessage = "Just type your question below and we will do our best to answer it.";

export const userInputPlaceholder = "Enter your message here";


export const localStorageMessagesKey = "messages";


export const backendEndpoint = "http://slowwly.robertomurray.co.uk/delay/600/url/http://www.google.co.uk";