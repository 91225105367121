import React from 'react';
import "./style.scss";

const Spinner = () => (
  <div className="Spinner">
    <div className="bounce1"/>
    <div className="bounce2"/>
    <div className="bounce3"/>
  </div>
);

export default Spinner;
