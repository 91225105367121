import React from 'react';
import Base from './Base';

export default props => (
  <Base {...props}>
    <path 
      d="M217.82,87,31.75,4.66A19.15,19.15,0,0,0,3.06,21.23v1.85L38.68,92.6A28.25,28.25,0,0,1,38.37,119L3.13,184v1.95a19,19,0,0,0,9.54,16.55,19.48,19.48,0,0,0,9.76,2.65,17.26,17.26,0,0,0,8.65-2.25l186.8-82.75a19.1,19.1,0,0,0,0-33.11Zm-6.88,19.48L24.15,189.24a3.74,3.74,0,0,1-3.82,0,3.9,3.9,0,0,1-1.62-1.83l33.17-61a43.27,43.27,0,0,0,4.31-12.94h69.66a7.67,7.67,0,0,0,0-15.33H56.25a43.46,43.46,0,0,0-3.92-12.46L18.63,19.82a3.85,3.85,0,0,1,1.66-1.92,4.42,4.42,0,0,1,4.6.37L210.46,100.4a3.79,3.79,0,0,1,1.66,3.07A3.84,3.84,0,0,1,210.94,106.5Z" 
    />
  </Base>
);
