import React from 'react';
import PropTypes from 'prop-types';

const BaseIcon = ({
  children,
  color,
  height,
  viewBox,
  width,
  ...rest
}) => (
  <svg
    fill={color}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    {...rest}
  >
    {children}
  </svg>
);

BaseIcon.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.array.isRequired,
  ]).isRequired,
  color: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
};

BaseIcon.defaultProps = {
  color: '#fff',
  height: '20px',
  viewBox: '0 0 50 50',
  width: '20px',
};

export default BaseIcon;
