import React from 'react';
import Base from './Base';

export default props => (
  <Base {...props}>
    <path 
      d="M36.574,11.945H13.426a7.508,7.508,0,0,0-7.508,7.508V30.547a7.5,7.5,0,0,0,7.2,7.492l-1.5,8.306,9.5-8.29H36.574a7.508,7.508,0,0,0,7.508-7.508V19.453A7.508,7.508,0,0,0,36.574,11.945Z" 
      transform="translate(-5.918 -11.945)"
    />
  </Base>
);
