import React from 'react';
import "./style.scss";
import Spinner from "ChatWindow/components/Spinner";
import PropTypes from "prop-types";
 
const Message = ({ isUser, isLoading, text, link }) => (
  <div className={"Message " + (isUser ? "Message-User" : "Message-Agent")}>
    {isLoading ? (
      <Spinner/>
    ) : (
      <>
        {text}
        {
          link &&
          // TODO change link display
          <a href={link} target="_blank" rel="noopener noreferrer">Default link name</a>
        }
      </>
    )}
  </div>
);

Message.propTypes = {
  isUser: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  text: PropTypes.string.isRequired,
  link: PropTypes.string,
}

export default Message;
