import React from 'react';
import {
  Chat,
  Footer,
  Header,
} from './components';
import './styles.scss';
import PropTypes from "prop-types";

const ChatWindow = ({ 
    onChatHide, 
    onMessageSubmit, 
    onInputChange, 
    inputValue, 
    messages, 
    isLoading
  }) => (
  <div className="ChatWindow">
    <Header
      onChatHide={onChatHide}
    />
    <Chat
      messages={messages}
      isLoading={isLoading}
    />
    <Footer
      inputValue={inputValue}
      onInputChange={onInputChange}
      onSubmit={onMessageSubmit}
    />
  </div>
)

ChatWindow.propTypes = {
  onChatHide: PropTypes.func.isRequired,
  onMessageSubmit: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  messages: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default ChatWindow;
