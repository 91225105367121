import React from 'react';
import Base from './Base';

export default props => (
  <Base {...props}>
    <path 
      d="M17.83,15,29,3.81A2,2,0,1,0,26.19,1L15,12.17,3.81,1A2,2,0,0,0,1,3.81L12.17,15,1,26.19A2,2,0,1,0,3.81,29L15,17.83,26.19,29A2,2,0,0,0,29,26.19Z" 
      transform="translate(-0.39 -0.39)" 
    />
  </Base>
);
