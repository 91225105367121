import React from 'react';
import "./style.scss";
import { 
  userInputPlaceholder,
} from "shared/constants";
import PropTypes from "prop-types";
import { isNotWhitespace } from "shared/helpers";
import { IconSend } from "shared/components";

class Footer extends React.Component {
  handleOnKeyDown = (e) => {
    const { onSubmit } = this.props;
    if (e.key === "Enter" && !e.shiftKey) {
      onSubmit(e);
    }
  }

  componentDidMount() {
    this.userInput.focus();
  }

  render() {
    const { inputValue, onInputChange, onSubmit } = this.props;
    return (
      <div className="Footer">
        <form onSubmit={onSubmit}>
          <textarea 
            onKeyDown={this.handleOnKeyDown}
            placeholder={userInputPlaceholder} 
            onChange={onInputChange} 
            value={inputValue} 
            ref={(input) => { this.userInput = input; }} 
          />
          <div 
            className={"flex-center send " + (isNotWhitespace(inputValue) ? "button" : "")}
            onClick={onSubmit}
          >
            <IconSend 
              width="15"
              height="13"
              viewBox="0 0 230.52 207.23" 
              color="#c4c8d4" 
              className={isNotWhitespace(inputValue) ? "show" : "hide"}
            />
          </div>
        </form>
      </div>
    );
  }
}

Footer.propTypes = {
  inputValue: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default Footer;
