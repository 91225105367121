import React from 'react';
import "./style.scss";
import Message from '../Message';
import PropTypes from "prop-types";

class Chat extends React.Component {
  componentDidMount () {
    this.scrollToBottom();
  }

  componentDidUpdate (prevProps) {
    const { messages } = this.props;
    if (JSON.stringify(messages) !== JSON.stringify(prevProps.messages)) {
      this.scrollToBottom();
    }
  }

  scrollToBottom = () => {
    this.chatContainer.scrollTop = this.chatContainer.offsetHeight;
  }

  render() {
    const { messages, isLoading } = this.props;

    return (
      <div className="Chat" ref={(div) => { this.chatContainer = div; }} >
        {
          messages.map((message, index) => (
            <Message 
              key={index}
              isUser={!!message.isUser}
              text={message.text}
              link={message.link}
            />
          ))
        }
        {
          isLoading &&
          <Message 
              key="loading"
              isUser={false}
              text=""
              isLoading={true}
            />
        }
        <div className="signature">
          powered by kwiki.io
        </div>
      </div>
    );
  } 
}

Chat.propTypes = {
  messages: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default Chat;
